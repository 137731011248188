import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpParams,
} from "@angular/common/http";
import { database, environment } from "environments/environment";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { AuthService } from "./auth.service";
import { RestService } from "./rest.service";
import Swal from "sweetalert2";

// const options = {
//   headers: new HttpHeaders()
//     // .set("Authorization", "Basic " + localStorage.getItem("auth"))
//     .set('Content-Type','application/json'),
//     withCredentials: true,
// };

const headers = new HttpHeaders({
  "Content-Type": "application/json",
});
const options = { headers: headers, withCredentials: true };

@Injectable({
  providedIn: "root",
})
export class OdooService {
  constructor(private http: HttpClient, private router: Router,private rest:RestService) {}

  //   createSession() {
  //     console.log("from odoo session");
  //     // const body = new URLSearchParams();
  //     // body.set("login", username);
  //     // body.set("password", password);
  //     // body.set("db","odoo");
  //     var body = {
  //       params: {
  //         login: "nizam@stsc.ae",
  //         password: "Legion@2020",
  //         db: "odoo",
  //       },
  //     };
  //     return this.http.post(environment.odooendpoint + "auth/", JSON.stringify(body), options);
  //   }
  // }

  authenticate(username, password) {
    console.log("hi from odoo");
    //let db =environment.d
    const body = {
      params: {
        jsonrpc: "2.0",
        login: username,
        password: password,
        //db: "demo.stsc.ae",
        db:database.url

      },
    };
    const url = "https://hr.stsc.ae/auth/";
    // const url = 'http://localhost:8069/auth/';

    return this.http
      .post(environment.odooendpoint + "auth/", JSON.stringify(body), options);

  }
  getEmployees(query?) {
    //const url = 'https://hr.stsc.ae/api/hr.employee/';
    // const url = 'http://localhost:8069/api/hr.employee/';

    var url = query
      ? environment.odooendpoint + "api/hr.employee/" + query
      : environment.odooendpoint + "api/hr.employee/";
    return this.http.get(url, { withCredentials: true });
  }

  getAttendences(start, end) {
    var params =
      '[["write_date",' +
      '">=","' +
      start +
      '"],' +
      '["write_date",' +
      '"<=","' +
      end +
      '"]]';

    var url = environment.odooendpoint + "api/hr.attendance/?filter=" + params;
    console.log(url);

    return this.http.get(url, {
      withCredentials: true,
    });
  }

  getLeaves() {
    return this.http.get(environment.odooendpoint + "api/hr.leave/", {
      withCredentials: true,
    });
  }

  getServices(query?) {
    return this.http.get(
      environment.odooendpoint + "api/service.request/",
      { withCredentials: true }
    );
  }
  getService_details(id,query?) {
    return this.http.get(
      environment.odooendpoint + "api/service.request/" +id+ query,
      { withCredentials: true }
    );
  }

  getEmployee_detals(id, query?) {
    //const url = 'https://hr.stsc.ae/api/hr.employee/';
    // const url = 'http://localhost:8069/api/hr.employee/';
    return this.http.get(
      environment.odooendpoint + "api/hr.employee/" + id + query,
      { withCredentials: true }
    );
  }

  getProducts() {
    return this.http.get(environment.odooendpoint + "api/product.product/", {
      withCredentials: true,
    });
  }

  getDepartments() {
    return this.http.get(environment.odooendpoint + "api/hr.department/", {
      withCredentials: true,
    });
  }

  get_tasks() {
    return this.http.get(environment.odooendpoint + "api/project.task/", {
      withCredentials: true,
    });
  }

  get_payroll() {
    return this.http.get(environment.odooendpoint + "api/hr.payslip/", {
      withCredentials: true,
    });
  }

  get_docs(id?) {
    console.log(id);

    const params = new HttpParams().set("query", "{" + id + "}");
    const options = { headers: headers, withCredentials: true, params };

    return id
      ? this.http.get(
          environment.odooendpoint + "api/hr.employee.document/",
          options
        )
      : this.http.get(environment.odooendpoint + "api/hr.employee.document/", {
          withCredentials: true,
        });
  }

  get_contracts() {
    return this.http.get(environment.odooendpoint + "api/hr.contract/", {
      withCredentials: true,
    });
  }

  get_docstype() {
    return this.http.get(environment.odooendpoint + "api/document.type/", {
      withCredentials: true,
    });
  }

  get_departments() {
    return this.http.get(environment.odooendpoint + "api/hr.department", {
      withCredentials: true,
    });
  }

  get_jobpossitions() {
    return this.http.get(environment.odooendpoint + "api/hr.job", {
      withCredentials: true,
    });
  }

  get_odoo(model) {
    return this.http.get(environment.odooendpoint + "api/" + model, {
      withCredentials: true,
    });
  }

  create_model(data) {
    const params = {
      data: JSON.stringify(data),
    };
    console.log(params);
    ///web/dataset/call_kw
    return this.http.post(
      environment.odooendpoint + "web/dataset/call_kw",
      params,
      { withCredentials: true }
    );
  }

  odoo_api_call(models = [], model = "", method = "create") {
    const params = {
      jsonrpc: "2.0",
      method: "call",
      params: {
        args: models,
        model: model,
        method: method,
        kwargs: {},
      },
    };

    return this.http.post(
      environment.odooendpoint + "web/dataset/call_kw",
      params,
      { withCredentials: true }
    );
  }

  odoo_api_update(models = [], model = "") {
    const params = {
      jsonrpc: "2.0",
      method: "call",
      params: {
        args: models,
        model: model,
        method: "write",
        kwargs: {},
      },
    };

    //console.log("before flight",params);

    return this.http.post(
      environment.odooendpoint + "web/dataset/call_kw/"+model+"/write",
      params,
      { withCredentials: true }
    );
  }

  odoo_api_delete(model,id){

    const params = {
      jsonrpc: "2.0",
      method: "call",
      params: {
        args: [id],
        model: model,
        method: "unlink",
        kwargs: {},
      },
    };

    return this.http.post(
      environment.odooendpoint + "web/dataset/call_kw/"+model+"/unlink",
      params,
      { withCredentials: true }
    );

  }

}
