import { Injectable } from "@angular/core";
import { webSocket } from "rxjs/webSocket";
import { environment } from "../../environments/environment";
export const WS_ENDPOINT = environment.wsEndpoint;
export const RECONNECT_INTERVAL = environment.reconnectInterval;
import { HttpClient } from "@angular/common/http";
import * as moment from "moment";

@Injectable({
  providedIn: "root",
})
export class DataService {
  public socket;
  public devices = [];
  public employees = [];
  public doc_types = [];
  public markerPositions = [];
  public filteredMarkers = [];
  public currentStatus = {};
  public searchParam = "";
  public companies = [];
  public jobpossitions: any = [];
  public reFit = false;

  constructor(private httpClient: HttpClient) {}
}
