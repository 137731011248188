import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { NavigationEnd, NavigationStart, Router } from "@angular/router";
import { Utils } from "app/helper/_utils";
import { DataService } from "app/service/data.service";
import { RestService } from "app/service/rest.service";
import { ToastrService } from "ngx-toastr";

import {
  Location,
  LocationStrategy,
  PathLocationStrategy,
  PopStateEvent,
  PlatformLocation,
} from "@angular/common";
import { NavbarComponent } from "app/shared/navbar/navbar.component";
import PerfectScrollbar from "perfect-scrollbar";
import { Observable, Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { OdooService } from "app/service/odoo.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
@Component({
  selector: "app-admin-layout",
  templateUrl: "./admin-layout.component.html",
  styleUrls: ["./admin-layout.component.scss"],
})
export class AdminLayoutComponent implements OnInit {
  private _router: Subscription;
  // url: string;
  url: string;
  location: Location;
  private lastPoppedUrl: string;
  private yScrollStack: number[] = [];
  @ViewChild("sidebar", { static: false }) sidebar;
  @ViewChild(NavbarComponent, { static: false }) navbar: NavbarComponent;

  apiLoaded: Observable<boolean>;

  constructor(
    public rest: RestService,
    public service: DataService,
    public odooService: OdooService,
    public toastr: ToastrService,
    public utils: Utils,
    location: Location,
    private router: Router
  ) {
    this.location = location;
    router.events.subscribe((val) => {
      if (!this.isMap()) {
        if (document.getElementById("height-helper")) {
          $("#height-helper").css({
            height: "100%",
            overflow: "visible",
          });
        }
      }
    });
  }

  public devices = [];
  public geofences = [];
  public permissions = [];

  ngOnInit() {
    this.getCompanies();
    this.initializeViews();
    this.getDevices();
    this.getCarStatusCount();
  }

  getDevices() {
    this.rest.getDevices().subscribe((response) => {
      this.service.devices = response.data;
    });
  }

  getCarStatusCount() {
    this.rest.getCarStatusCount().subscribe((response) => {
      this.service.devices = response.data;
    });
  }
  getEmployees() {
    var query =
      "?query={id,name,company_id,hr_presence_state,image_128,work_email}";
    this.odooService.getEmployees(query).subscribe((response) => {
      this.service.employees = response["result"];
      this.get_jobpossitions();
      // console.log(this.service.employees);
    });
  }
  get_jobpossitions() {
    this.odooService.get_odoo("hr.job").subscribe((res) => {
      this.service.jobpossitions = res["result"];
      console.log(res);
    });
  }

  getCompanies() {
    const company = {
      id: 1,
      name: "stsc",
    };

    this.service.companies.push(company);
  }
  getGeoFences() {}

  initializeViews() {
    const elemMainPanel = <HTMLElement>document.querySelector(".main-panel");
    const elemSidebar = <HTMLElement>(
      document.querySelector(".sidebar .sidebar-wrapper")
    );
    this.location.subscribe((ev: PopStateEvent) => {
      this.lastPoppedUrl = ev.url;
    });
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        if (event.url !== this.lastPoppedUrl) {
          this.yScrollStack.push(window.scrollY);
        }
      } else if (event instanceof NavigationEnd) {
        if (event.url === this.lastPoppedUrl) {
          this.lastPoppedUrl = undefined;
          window.scrollTo(0, this.yScrollStack.pop());
        } else {
          window.scrollTo(0, 0);
        }
      }
    });
    this._router = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        elemMainPanel.scrollTop = 0;
        elemSidebar.scrollTop = 0;
      });
    const html = document.getElementsByTagName("html")[0];
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      let ps = new PerfectScrollbar(elemMainPanel);
      ps = new PerfectScrollbar(elemSidebar);
      html.classList.add("perfect-scrollbar-on");
    } else {
      html.classList.add("perfect-scrollbar-off");
    }
    this._router = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.navbar.sidebarClose();
      });
  }

  public isMap() {
    if (this.location.prepareExternalUrl(this.location.path()) === "#/maps") {
      return true;
    } else {
      return false;
    }
  }

  isMac(): boolean {
    let bool = false;
    if (
      navigator.platform.toUpperCase().indexOf("MAC") >= 0 ||
      navigator.platform.toUpperCase().indexOf("IPAD") >= 0
    ) {
      bool = true;
    }
    return bool;
  }
}
