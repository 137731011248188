import { Injectable } from "@angular/core";
import { catchError, timeout } from "rxjs/operators";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpResponse,
  HttpParams,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { map } from "rxjs/operators";
import * as moment from "moment";
//import { env } from "process";
import { environment } from "environments/environment";
import { data } from "jquery";
import { Router } from "@angular/router";
import { Utils } from "app/helper/_utils";
import { ClassGetter } from "@angular/compiler/src/output/output_ast";

const options = {
  headers: new HttpHeaders()
    .set("Authorization", "Basic " + localStorage.getItem("auth"))
    .set("Accept", "application/json"),
};

@Injectable({
  providedIn: "root",
})
export class RestService {
  appApi = "";
  groups = "";

  constructor(
    private http: HttpClient,
    private router: Router,
    private utils: Utils
  ) {
    this.setUpApi();
  }

  // getDevices(): Observable<any> {
  //   return this.http
  //     .get(environment.baseEndpoint + "devices", options)
  //     .pipe(map(this.extractData), catchError(this.handleError));
  // }

  getGpsToken() {
    const token = localStorage.getItem("gps-token");
    return token;
  }

  proxify(url) {
    const proxyUrl = "https://cors.stsc.ae/";
    return proxyUrl + url;
  }

  getDevices(): Observable<any> {
    const token = this.getGpsToken();
    const carsUrl = `${environment.baseEndpoint}/car/getByUserId.do`;
    const params = new HttpParams().set("token", token);

    return this.http
      .get(this.proxify(carsUrl), { params })
      .pipe(map(this.extractData), catchError(this.handleError));
  }

  getCarStatusCount(): Observable<any> {
    const token = this.getGpsToken();
    const statusUrl = `${environment.baseEndpoint}/user/getCarStatusCount.do`;
    const params = new HttpParams().set("token", token);

    return this.http
      .get<any>(this.proxify(statusUrl), { params })
      .pipe(map(this.extractData), catchError(this.handleError));
  }

  getLocationByUserId(): Observable<any> {
    const locationByUserUrl = `${environment.baseEndpoint}/carStatus/getByUserId.do`;
    const token = this.getGpsToken();
    const userId = localStorage.getItem("gps-user-id");
    const params = new HttpParams()
      .set("token", token)
      .set("targetUserId", userId)
      .set("mapType", "2");

    return this.http
      .get(this.proxify(locationByUserUrl), { params })
      .pipe(map(this.extractData), catchError(this.handleError));
  }

  manipulateDate(date) {
    const s = date.split("-");
    return new Date(s[0], s[1] - 1, s[2], 0, 0, 0);
  }

  setUpApi() {
    this.appApi = environment.stscApiBase;
  }

  getCaptains(payload): Observable<any> {
    const url = this.appApi + "getCaptainsByDevice/";
    return this.http
      .post(url, payload, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Token " + localStorage.getItem("token"),
        }),
      })
      .pipe(map(this.extractData), catchError(this.handleError));
  }

  getCaptainProfile(payload): Observable<any> {
    const url = this.appApi + "getProfile/";
    return this.http
      .post(url, payload, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Token " + localStorage.getItem("token"),
        }),
      })
      .pipe(map(this.extractData), catchError(this.handleError));
  }

  getCar(payload): Observable<any> {
    const url = this.appApi + "getCar/";
    return this.http
      .post(url, payload, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Token " + localStorage.getItem("token"),
        }),
      })
      .pipe(map(this.extractData), catchError(this.handleError));
  }

  getAllCaptains(): Observable<any> {
    const url = this.appApi + "getAllDrivers/";
    return this.http
      .get(url, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",

          Authorization: "Token " + localStorage.getItem("token"),
        }),
      })
      .pipe(map(this.extractData), catchError(this.handleError));
  }

  getAllCars(): Observable<any> {
    const url = this.appApi + "getAllCars/";
    return this.http
      .get(url, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",

          Authorization: "Token " + localStorage.getItem("token"),
        }),
      })
      .pipe(map(this.extractData), catchError(this.handleError));
  }

  submitFine(payload) {
    const url = this.appApi + "submitFine/";
    return this.http
      .post(url, payload, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",

          Authorization: "Token " + localStorage.getItem("token"),
        }),
      })
      .pipe(map(this.extractData), catchError(this.handleError));
  }

  deleteFine(payload) {
    const url = this.appApi + "deleteFine/";
    return this.http
      .post(url, payload, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",

          Authorization: "Token " + localStorage.getItem("token"),
        }),
      })
      .pipe(map(this.extractData), catchError(this.handleError));
  }

  changeFineStatus(payload) {
    const url = this.appApi + "changeFineStatus/";
    return this.http
      .post(url, payload, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",

          Authorization: "Token " + localStorage.getItem("token"),
        }),
      })
      .pipe(map(this.extractData), catchError(this.handleError));
  }

  getAllFines(): Observable<any> {
    const url = this.appApi + "getFines/";
    return this.http
      .post(
        url,
        {},
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json",

            Authorization: "Token " + localStorage.getItem("token"),
          }),
        }
      )
      .pipe(map(this.extractData), catchError(this.handleError));
  }

  getAllUsers(): Observable<any> {
    const url = this.appApi + "getAllUsers/";
    return this.http
      .get(url, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",

          Authorization: "Token " + localStorage.getItem("token"),
        }),
      })
      .pipe(map(this.extractData), catchError(this.handleError));
  }

  getStatistics(from: string, to: string) {
    const from_ = this.manipulateDate(from);
    from_.setHours(0, 0, 0, 0);
    const to_ = this.manipulateDate(to);
    to_.setHours(23, 59, 59, 999);
    const payload = {
      start_date: from,
      end_date: to,
      employee_id: environment.ADMIN_ROLE,
    };
    const url = environment.stscApiBase + "getReportStatistics/";
    return this.http
      .post(url, JSON.stringify(payload), {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Token " + localStorage.getItem("token"),
        }),
      })
      .pipe(map(this.extractData), catchError(this.handleError));
  }

  private extractData(res: Response): any {
    const body = res;
    return body || {};
  }

  private handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      console.error("An error occurred:", error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    return throwError("Something bad happened; please try again later.");
  }

  getBills(from: string, to: string) {
    const from_ = this.manipulateDate(from);
    from_.setHours(0, 0, 0, 0);
    const to_ = this.manipulateDate(to);
    to_.setHours(23, 59, 59, 999);

    const payload = {
      start_date: from_,
      end_date: to_,
      employee_id: environment.ADMIN_ROLE,
    };

    const url = this.appApi + "getBillsByDate/";
    return this.http
      .post(url, JSON.stringify(payload), {
        headers: new HttpHeaders({
          "Content-Type": "application/json",

          Authorization: "Token " + localStorage.getItem("token"),
        }),
      })
      .pipe(map(this.extractData), catchError(this.handleError));
  }

  create_session_stsc(username, password) {
    let body = {
      username: username,
      password: password,
    };
    let url = environment.stscApiBase + "api-token-auth/";
    return this.http.post(url, body);
  }

  getToken() {
    let token = localStorage.getItem("token");
    let auth = localStorage.getItem("auth");
    return token && auth ? token : "";
  }

  getProfile(payload) {
    const url = this.appApi + "getProfile/";
    return this.http
      .post(url, JSON.stringify(payload), {
        headers: new HttpHeaders({
          "Content-Type": "application/json",

          Authorization: "Token " + localStorage.getItem("token"),
        }),
      })
      .pipe(map(this.extractData), catchError(this.handleError));
  }

  getEvgFines(): Observable<any> {
    const url = this.appApi + "getAssignedFines/";
    return this.http.get(url, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",

        Authorization: "Token " + localStorage.getItem("token"),
      }),
    });
  }
  get_un_EvgFines(): Observable<any> {
    const url = this.appApi + "getTrafficFinesEVG/";
    return this.http.get(url, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        //Authorization: "Basic " + btoa("stscadmin:Legion@2020"),
        Authorization: "Token " + localStorage.getItem("token"),
      }),
    });
  }

  get_user_permission() {
    let token = localStorage.getItem("auth");
    //console.log(token);
    const url = this.appApi + "getUserPermissions/";
    return this.http.get(url, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        //Authorization: "Basic " + btoa(""),
        Authorization: "Token " + localStorage.getItem("token"),
      }),
      //withCredentials:true,
    });
  }

  getCars() {
    let httOptions = {
      headers: new HttpHeaders()
        .set("Authorization", "Token " + localStorage.getItem("token"))
        .set("Accept", "application/json"),
    };
    return this.http.get(environment.stscApiBase + "rest/car/", httOptions);
  }

  getEmployees() {
    let httOptions = {
      headers: new HttpHeaders()
        .set("Authorization", "Token " + localStorage.getItem("token"))
        .set("Accept", "application/json"),
    };

    return this.http.get(
      environment.stscApiBase + "rest/employee/",
      httOptions
    );
  }
}
