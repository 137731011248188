import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AuthService } from './service/auth.service'

export const AppRoutes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthService],
    children: [{
      path: '',
      canActivateChild: [AuthService],
      loadChildren: () => import('./layouts/admin/admin-layout.module').then(m => m.AdminLayoutModule)
    },

  ]
  }, {
    path: '',
    component: AuthLayoutComponent,
    children: [{
      path: '',
      loadChildren: () => import('./user/user.module').then(m => m.UserModule)
    },

    {
      path: '**', redirectTo: '/dashboard'
    }]

  },

]
