import { Injectable } from '@angular/core';
import { catchError, timeout } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
//import { env } from 'process';
import { environment } from 'environments/environment';
import { data } from 'jquery';




const options = {
  headers: new HttpHeaders()
    .set('Authorization', 'Token ' +localStorage.getItem('token'))
    .set('Accept', 'application/json'),
  withCredentials: true
}

@Injectable({
  providedIn: 'root'
})
export class AccidentsService {

  constructor(private http: HttpClient) { }


    payload = {
     'employee_id': 1,

   }

  getRepairs(): Observable<any> {
    const url = environment.stscApiBase + 'getRepairs/';
    return this.http.post(url,this.payload,options).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }

  private extractData(res: Response): any {
    const body = res;
    //console.log(res);
    return body || {};
  }

  private handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError(
      'Something bad happened; please try again later.');
  }

}
