import {
  Component,
  OnInit,
  Renderer2,
  ViewChild,
  ElementRef,
  Directive,
  Injectable,
} from "@angular/core";
import { ROUTES } from "../../sidebar/sidebar.component";
import {
  Router,
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
} from "@angular/router";
import {
  Location,
  LocationStrategy,
  PathLocationStrategy,
} from "@angular/common";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { DataService } from "app/service/data.service";
import { FormGroup, FormControl } from "@angular/forms";
import { Utils } from "app/helper/_utils";
import { GoogleMap } from "@angular/google-maps";
import { RestService } from "app/service/rest.service";
declare var $: any;

var misc: any = {
  navbar_menu_visible: 0,
  active_collapse: true,
  disabled_collapse_init: 0,
};

@Component({
  moduleId: module.id,
  selector: "navbar-cmp",
  templateUrl: "navbar.component.html",
})
export class NavbarComponent implements OnInit {
  private listTitles: any[];
  location: Location;
  private nativeElement: Node;
  private toggleButton;
  private sidebarVisible: boolean;
  private _router: Subscription;
  public open: boolean = false;

  full_name = "";
  designation = "";
  profile_url = "";
  apiLoaded = false;

  searchForm;

  notiEnabled;

  @ViewChild("navbar-cmp", { static: false }) button;

  constructor(
    location: Location,
    private renderer: Renderer2,
    private element: ElementRef,
    private router: Router,
    private data: DataService,
    private utils: Utils,
    private rest: RestService
  ) {
    this.location = location;
    this.nativeElement = element.nativeElement;
    this.sidebarVisible = false;
  }

  ngOnInit() {
    this.listTitles = ROUTES.filter((listTitle) => listTitle);

    this.searchForm = new FormGroup({
      search: new FormControl(""),
    });

    const navbar: HTMLElement = this.element.nativeElement;
    const body = document.getElementsByTagName("body")[0];
    this.toggleButton = navbar.getElementsByClassName("navbar-toggler")[0];
    if (body.classList.contains("sidebar-mini")) {
      misc.sidebar_mini_active = true;
    }

    this._router = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        const $layer = document.getElementsByClassName("close-layer")[0];
        if ($layer) {
          $layer.remove();
        }
      });
  }

  minimizeSidebar() {
    const body = document.getElementsByTagName("body")[0];

    if (misc.sidebar_mini_active === true) {
      body.classList.remove("sidebar-mini");
      misc.sidebar_mini_active = false;
    } else {
      setTimeout(function () {
        body.classList.add("sidebar-mini");

        misc.sidebar_mini_active = true;
      }, 300);
    }

    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(function () {
      window.dispatchEvent(new Event("resize"));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(function () {
      clearInterval(simulateWindowResize);
    }, 1000);
  }

  isMobileMenu() {
    if (window.outerWidth < 991) {
      return false;
    }
    return true;
  }

  sidebarOpen() {
    var toggleButton = this.toggleButton;
    var html = document.getElementsByTagName("html")[0];
    setTimeout(function () {
      toggleButton.classList.add("toggled");
    }, 500);
    const mainPanel = <HTMLElement>(
      document.getElementsByClassName("main-panel")[0]
    );
    if (window.innerWidth < 991) {
      mainPanel.style.position = "fixed";
    }
    html.classList.add("nav-open");
    this.sidebarVisible = true;
  }
  sidebarClose() {
    var html = document.getElementsByTagName("html")[0];
    this.toggleButton.classList.remove("toggled");
    this.sidebarVisible = false;
    html.classList.remove("nav-open");
    const mainPanel = <HTMLElement>(
      document.getElementsByClassName("main-panel")[0]
    );

    if (window.innerWidth < 991) {
      setTimeout(function () {
        mainPanel.style.position = "";
      }, 500);
    }
  }

  searchMarkers() {
    //const value = event.target.value.toLowerCase();
    const value = this.searchForm.value.search.toLowerCase();
    //console.log(value);
    if (this.data.markerPositions.length > 0) {
      this.data.searchParam = value;
      this.data.reFit = true;
      // console.log(this.data.markerPositions.length);
      this.data.filteredMarkers = this.data.markerPositions.filter((marker) => {
        if (
          JSON.stringify(marker).toLowerCase().includes(value.toLowerCase())
        ) {
          return marker;
        }
      });
      // console.log(this.data.filteredMarkers);
    }
    // this.data.bounds = this.utils.getBounds(this.data.filteredMarkers);
    // this.data.updateBounds(value);
    // this.gmap.fitBounds(this.data.bounds);
  }

  fieldsChange(event) {
    console.log(event);
    if (this.notiEnabled) {
      this.notiEnabled = false;
      localStorage.setItem("notiEnabled", "false");
    } else {
      this.notiEnabled = true;
      localStorage.setItem("notiEnabled", "true");
    }
  }

  openAccount() {
    this.apiLoaded = false;
    this.notiEnabled =
      localStorage.getItem("notiEnabled") == "true"
        ? true
        : localStorage.getItem("notiEnabled") == "false"
        ? false
        : true;
    $("#accountModel").modal("show");
    // $('#accountModel').on('shown.bs.modal', function () {
    //     this.notiEnabled = localStorage.getItem('notiEnabled') == 'true' || true;
    // });
    const auth = localStorage.getItem("auth");
    const username = atob(auth).split(":")[0];
    let payload = {};
    if (username.includes("@")) {
      payload = {
        email: username,
      };
    } else if (username === "admin") {
      payload = {
        employee_id: 1,
      };
    } else {
      this.apiLoaded = true;
      this.full_name = this.utils.capitalize(username);
    }
    if (!jQuery.isEmptyObject(payload)) {
      this.rest.getProfile(payload).subscribe((result) => {
        const data = result.data;
        this.apiLoaded = true;
        this.full_name = this.utils.capitalize(data.full_name);
        this.designation = this.utils.capitalize(data.designation);
        if (data.profile_image.toLowerCase().includes("pic_folder")) {
          this.profile_url = "/assets/img/placeholder.jpg";
        } else {
          this.profile_url = this.utils.fixFirebaseUrl(data.profile_image);
        }
        console.log(this.profile_url);
      });
    }
  }

  logout() {
    localStorage.clear();
    this.router.navigate(["/login"]);
  }

  sidebarToggle() {
    // var toggleButton = this.toggleButton;
    // var body = document.getElementsByTagName('body')[0];
    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  }

  getTitle() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    //console.log(titlee);
    if (titlee.charAt(0) === "#") {
      titlee = titlee.slice(1);
    }
    for (var item = 0; item < this.listTitles.length; item++) {
      var parent = this.listTitles[item];
      if (parent.path === titlee) {
        //console.log(parent.title);
        return parent.title;
      } else if (parent.children) {
        var children_from_url = titlee.split("/")[2];
        for (var current = 0; current < parent.children.length; current++) {
          if (parent.children[current].path === children_from_url) {
            return parent.children[current].title;
          }
        }
      }
    }
    //return children_from_url.split('?')[0];
  }

  getPath() {
    return this.location.prepareExternalUrl(this.location.path());
  }
}
