import { Injectable } from '@angular/core';
import { catchError, timeout } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
//import { env } from 'process';
import { environment } from 'environments/environment';
import { data } from 'jquery';
import { GlobalService } from './global.service';




const options = {
  headers: new HttpHeaders()
    .set('Authorization', 'Basic ' + localStorage.getItem('auth'))
    .set('Accept', 'application/json'),
  withCredentials: true
}

@Injectable({
  providedIn: 'root'
})
export class MaterialRequestsService {

  from: string;
  to: string;

  constructor(private http: HttpClient,
    private global:GlobalService) {
    {
      if (this.global.from_date && this.global.to_date) {
        this.from = this.global.from_date;
        this.to = this.global.to_date
      } else {
        const from = moment();
        const to = moment();
        this.from = from.year() + '-' + (from.month() + 1) + '-' + from.date();
        this.to = to.year() + '-' + (to.month() + 1) + '-' + to.date();
      }

    }
  }


  //  payload = {
  //   'employee_id': 1,

  // }

  getMaterialrequests(): Observable<any> {
    const url = environment.stscApiBase + 'getMaterialRequests/';
    return this.http.get(url).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }

  private extractData(res: Response): any {
    const body = res;
    //console.log(res);
    return body || {};
  }

  private handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError(
      'Something bad happened; please try again later.');
  }
  handleRequest(selectedrequest:any): Observable<any> {
    const payload= selectedrequest;
    const url = environment.stscApiBase + 'handleRequest/';
    return this.http.post(url,payload).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }
}
