import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { RestService } from "../service/rest.service";
import { ToastrService } from "ngx-toastr";
import Swal from "sweetalert2";

@Injectable({ providedIn: "root" })
export class AuthService implements CanActivate {
  constructor(
    private router: Router,
    private rest: RestService,
    private toastr: ToastrService
  ) {}

  loading: boolean;
  permissions: [any];

  canActivate(router: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const token = this.rest.getToken();
    if (token) {
      return true;
    }
    this.router.navigate(["/login", { queryParams: { returnUrl: state } }]);
    return false;
  }

  canActivateChild(router: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const token = this.rest.getToken();
    if (token) {
      return true;
    }
    this.router.navigate(["/login", { queryParams: { returnUrl: state } }]);
    return false;
  }

  login(username: string, password: string) {
    this.loading = true;
    setTimeout(() => 3000);
    this.login_stsc(username, password);
  }

  login_stsc(username, password) {
    this.rest.create_session_stsc(username, password).subscribe(
      (res) => {
        localStorage.setItem("auth", btoa(username + ":" + password));
        let token = res["token"];
        localStorage.setItem("token", token);
        localStorage.setItem("gps-token", res["gps_auth_data"]["token"]);
        localStorage.setItem("gps-user-id", res["gps_auth_data"]["userId"]);
        localStorage.setItem("gps-user-name", res["gps_auth_data"]["userName"]);
        // this.get_permission(username, password);
        this.router.navigate(["/dashboard"]);
      },
      (err) => {
        Swal.fire("Please check your credentials").then((result) => {
          if (result.isConfirmed) {
            location.reload();
          }
        });
      }
    );
  }

  get_permission(username, password) {
    this.rest.get_user_permission().subscribe((res) => {
      this.loading = false;

      if (res["message"] == "success") {
        this.permissions = res["permissions"];
        this.router.navigate(["/dashboard"]);
        location.reload();
        localStorage.setItem("permissions", res["permissions"]);
      } else {
        this.router.navigate(["/dashboard"]);
      }
    });
  }
}
