
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from '@angular/core';

import { RouterModule } from '@angular/router';
import { ToastrModule } from "ngx-toastr";

import { SidebarModule } from './sidebar/sidebar.module';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule } from './shared/navbar/navbar.module';
import { FixedPluginModule } from './shared/fixedplugin/fixedplugin.module';

import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { HttpClientModule } from '@angular/common/http';
import { CardinalPipe } from './pipes/cardinal.pipe';
import { AuthService } from './service/auth.service';
import { Utils } from "./helper/_utils";
import { VacationService } from "./service/vacation.service";
import { MaterialRequestsService } from "./service/material-requests.service";
import { AccidentsService } from "./service/accidents.service";
import { CarsService } from "./service/cars.service";
import { DataService } from "./service/data.service";
import { environment } from 'environments/environment';
import { AppAnalyticsComponent } from './pages/reports/app-analytics/app-analytics.component';
import * as bootstrap from "bootstrap";
import * as $ from "jquery";
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import { CustomAdapter,CustomDateParserFormatter } from "./service/dateAdapter.service";
import { NgbDateAdapter,NgbDateParserFormatter } from "@ng-bootstrap/ng-bootstrap";
import { AttendanceService } from "./service/attendance.service";
//import { FileUploadService } from "./service/file-upload.service";

import { AngularFireModule } from '@angular/fire/';
import { FileUploadService } from 'app/service/file-upload.service';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { DataTablesModule } from "angular-datatables";
import { HrModuleModule } from "./pages/hr-module/hr-module.module";
import { AdminLayoutModule } from "./layouts/admin/admin-layout.module";
import { CommonModule } from "@angular/common";
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgSelectModule } from "@ng-select/ng-select";
import { FormsModule } from "@angular/forms";
import { LoandueComponent } from './pages/loandue/loandue.component';
import { TollsComponent } from './pages/reports/tolls/tolls.component';









//import { CommandcenterComponent } from './pages/commandcenter/commandcenter.component';




//import { DatetimerangepickerModule } from "angular-datetimerangepicker";





export const WS_ENDPOINT = environment.wsEndpoint;


@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    AppAnalyticsComponent,
















  ],
  imports: [
    BrowserAnimationsModule,
    RouterModule.forRoot(AppRoutes, {
      useHash:true
    }),
    SidebarModule,
    NavbarModule,
    ToastrModule.forRoot(),
    FooterModule,
    FixedPluginModule,
    HttpClientModule,
    DataTablesModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireStorageModule,
    CommonModule,
    FontAwesomeModule,
    NgSelectModule,
    FormsModule



    //NgxLoadingModule.forRoot({}),
   // NgxDaterangepickerMd.forRoot()






  ],
  providers: [
    AuthService,
    //{provide: NgbDateAdapter, useClass: CustomAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter},
    Utils, AuthService,
    Utils, VacationService,
    MaterialRequestsService,
    AccidentsService,
    CarsService,
    DataService,
    AttendanceService,
    FileUploadService,


  ],
  bootstrap: [AppComponent]
})
export class AppModule {

 }
