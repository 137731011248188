// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

import { SubscriptionLog } from "rxjs/internal/testing/SubscriptionLog";

//export const baseurl ='http://192.168.0.136:8000/';
export const baseurl = "https://stscapi.herokuapp.com/";

export const environment = {
  production: false,
  //wsEndpoint: 'wss://dashboard.stsc.ae/gps/api/socket',
  wsEndpoint: "wss://traccar.stsc.ae/api//socket",

  // wsEndpoint: "wss://traccar.stsc.ae/api/socket",
  // wsEndpoint: 'ws://148.66.132.59:8082/api/socket',
  // wsEndpoint: 'wss://warash.com:8083/api/socket',
  // baseEndpoint: "https://traccar.stsc.ae/api/",
  baseEndpoint: "https://www.whatsgps.com",
  //baseEndpoint: "https://dashboard.stsc.ae/gps/api/",
  reconnectInterval: 300,

  stscApiBase: `${baseurl}api/`,

  //stscApiBase: 'https://api.stsc.ae/api/',
  //stscApiBase: "https://stscapi.herokuapp.com/api/",
  odooendpoint: "https://hr.stsc.ae/",
  //odooendpoint:'https://demo.stsc.ae/',
  //odooendpoint:"https://demo.fdtech.ae/",
  db: "",
  kdApiBase: "./",
  ADMIN_ROLE: 1,
  map_key: "AIzaSyBdoZ63iSlkMdUGWV5he1cyxkDSbtu8zGo",
  refreshInterval: 60000,
  basePhotoUrl:
    "https://firebasestorage.googleapis.com/v0/b/ststracker-13468.appspot.com/o/",

  firebase: {
    apiKey: "AIzaSyCavjrLU3EV-B-JjA5ZpqLT04cU7q9IHRA",
    authDomain: "stscdashboard.firebaseapp.com",
    projectId: "stscdashboard",
    storageBucket: "stscdashboard.appspot.com",
    messagingSenderId: "643827204106",
    appId: "1:643827204106:web:723ab17ef182874b3c5ff3",
  },
};

let db = environment.odooendpoint.slice(8, -1);
export const database = {
  url: db,
};
