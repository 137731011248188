import { Injectable } from '@angular/core';
import { catchError, timeout } from "rxjs/operators";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { environment } from "environments/environment";
import { Observable, throwError } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  public from_date: string = "";
  public to_date: string = "";
  saliks = [];
  events;
  constructor( private http:HttpClient,) {


   }

   getGlobals(): Observable<any>{
      return this.http.get(environment.stscApiBase+"getGlobals/").pipe(map((this.extractData),catchError(this.handleError)))
   }

   private extractData(res: Response): any {
    const body = res;
    const carid = body['TYPE_CAR_ID']
   // console.log(cars);
    return carid || {};
  }

  private handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      console.error("An error occurred:", error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    return throwError("Something bad happened; please try again later.");
  }

}
