import { Component } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery'
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {


  constructor() {


  }

}
