import { Injectable } from '@angular/core';
import { catchError, timeout } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
//import { env } from 'process';
import { environment } from 'environments/environment';
import { data } from 'jquery';




const options = {
  headers: new HttpHeaders()
    .set('Authorization', 'Basic ' + localStorage.getItem('auth'))
    .set('Accept', 'application/json'),
  withCredentials: true
}

@Injectable({
  providedIn: 'root'
})
export class VacationService {


  selectedvacation;
  constructor(private http: HttpClient) { }


   payload = {
    'employee_id': 1,

  }
  getVacations(payload): Observable<any> {
    const url = environment.stscApiBase + 'addTraficFines/';
    return this.http.post(url, payload).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }

  private extractData(res: Response): any {
    const body = res;
   // console.log(res+'from services')
    return body || {};
  }

  private handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError(
      'Something bad happened; please try again later.');
  }

  acceptVacations(selectedvacation:object): Observable<any> {
    let payload=selectedvacation;
    const url = environment.stscApiBase + 'handleVacation/';
    return this.http.post(url, payload,{
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        //Authorization: "Basic " + btoa(""),
         Authorization: 'Token ' + localStorage.getItem('token'),
      }),
      //withCredentials:true,

    }).pipe(
      map(this.extractData),
      catchError(this.handleError)
    );
  }
}
