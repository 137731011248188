import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs/internal/Observable';
import { finalize } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  uploadPercent: Observable<number>;
  downloadUrl;
  constructor(private storage:AngularFireStorage) { }



  upload(f:File){
    //console.log(f);
    let folder='/uploads'
    const ext = f.name.split('.').pop();
    const path = `${folder}/${f.name}.${ext}`;

      const file = f;
      //const filePath = '../uploads';
      const fileRef = this.storage.ref(path);
      const task = this.storage.upload(path, file);
      this.uploadPercent=task.percentageChanges();

      task.snapshotChanges().pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe(downloadURL => {
            this.downloadUrl = downloadURL;
            //console.log(this.downloadUrl);

          });
        })
      ).subscribe

      return this.downloadUrl
}

    }






