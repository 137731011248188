import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { catchError, timeout } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';

const options = {
  headers: new HttpHeaders()
    .set("Authorization", "Token " + localStorage.getItem("token"))
    .set("Accept", "application/json"),
  // withCredentials: true,
};


@Injectable({
  providedIn: 'root'
})
export class AttendanceService {


  constructor(
    private http :HttpClient,
  ) { }

  getAttendance():Observable<any>{
    let payload ={};
    const url = environment.stscApiBase +'getJobs/';
    return this.http.post(url,payload,options).pipe(
    map(this.extractData),
    catchError(this.handleError)
  );
  }
  private extractData(res: Response): any {
    const body = res;
    //console.log(body);
    //console.log(res+'from services')
    return res || {};
  }

  private handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError(
      'Something bad happened; please try again later.');
  }
   getAttendance_bydate( from,to){

    let payload=
    {
      "start_date" : from,
      "end_date" : to,
    }
    const url = environment.stscApiBase +'getJobs/';
    return this.http.post(url,payload,options).pipe(
    map(this.extractData),
    catchError(this.handleError)
  );



   }

   getattendance_byemp(from,to,emp_id){
    let payload={
      "employee_id": emp_id,
      "start_date" : "",
      "end_date" :  ""
    };

    if(from&&to){

       payload={
        "employee_id": emp_id,
        "start_date" : from,
        "end_date" :   to
      }
    }



    const url = environment.stscApiBase +'getJobs/';
    return this.http.post(url,payload,options).pipe(
    map(this.extractData),
    catchError(this.handleError)
    )}
}
