import { CaptainDetailsService } from "app/service/Captain-details.service";
import { environment } from "environments/environment";
import * as moment from "moment";

export class Utils {
  constructor() {}
  public capitalize(input) {
    return !!input
      ? input
          .split(" ")
          .map(function (wrd) {
            return wrd.charAt(0).toUpperCase() + wrd.substr(1).toLowerCase();
          })
          .join(" ")
      : "";
  }

  public strip_and_upper(input) {
    return !!input ? input.replace(" ", "").toUpperCase() : "";
  }

  public camelCaseRemover(val) {
    let result = val.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
  }

  public getUsername() {
    const auth = localStorage.getItem("auth");
    const username = atob(auth).split(":")[0];
    return username;
  }

  public fixFirebaseUrl(url) {
    return (
      environment.basePhotoUrl +
      encodeURIComponent(url.substring(1).replace("media/", "")) +
      "?alt=media"
    );
  }

  public getNiceDateAndTime(date) {
    return moment(date).format("dddd, MMMM Do, YYYY h:mm:ss A");
  }

  public getISODate(date) {
    return moment(date, "dddd, MMMM Do, YYYY h:mm:ss A").toISOString();
  }

  public msToTime(s) {
    const ms = s % 1000;
    s = (s - ms) / 1000;
    const secs = s % 60;
    s = (s - secs) / 60;
    const mins = s % 60;
    const hrs = (s - mins) / 60;
    return hrs + ":" + mins + ":" + secs + "." + ms;
  }

  public formatDate(date) {
    return moment(date).format();
  }

  public checkUserType() {
    let type = atob(localStorage.getItem("auth"));
    if (type.split(":")[0] === "kd_admin") {
      return "kd";
    } else {
      return "stsc";
    }
  }

  public getBounds(markers) {
    // console.log(markers.length);
    let north;
    let south;
    let east;
    let west;
    for (const marker of markers) {
      north =
        north !== undefined
          ? Math.max(north, marker.position.latitude)
          : marker.position.latitude;
      south =
        south !== undefined
          ? Math.min(south, marker.position.latitude)
          : marker.position.latitude;
      east =
        east !== undefined
          ? Math.max(east, marker.position.longitude)
          : marker.position.longitude;
      west =
        west !== undefined
          ? Math.min(west, marker.position.longitude)
          : marker.position.longitude;
    }
    // const bounds = new google.maps.LatLngBounds(
    //     new google.maps.LatLng(north, south),
    //     new google.maps.LatLng(east, west)
    // );

    const bounds = {
      north,
      south,
      east,
      west,
    };
    return bounds;
  }

  public getCardinal(angle) {
    const degreePerDirection = 360 / 8;
    const offsetAngle = angle + degreePerDirection / 2;
    return offsetAngle >= 0 * degreePerDirection &&
      offsetAngle < 1 * degreePerDirection
      ? "N"
      : offsetAngle >= 1 * degreePerDirection &&
        offsetAngle < 2 * degreePerDirection
      ? "NE"
      : offsetAngle >= 2 * degreePerDirection &&
        offsetAngle < 3 * degreePerDirection
      ? "E"
      : offsetAngle >= 3 * degreePerDirection &&
        offsetAngle < 4 * degreePerDirection
      ? "SE"
      : offsetAngle >= 4 * degreePerDirection &&
        offsetAngle < 5 * degreePerDirection
      ? "S"
      : offsetAngle >= 5 * degreePerDirection &&
        offsetAngle < 6 * degreePerDirection
      ? "SW"
      : offsetAngle >= 6 * degreePerDirection &&
        offsetAngle < 7 * degreePerDirection
      ? "W"
      : "NW";
  }

  remove_duplicates_es6(arr) {
    let s = new Set(arr);
    let it = s.values();
    return Array.from(it);
  }

  remove_duplicates(array, prop) {
    return array.reduce((unique, o) => {
      if (!unique.some((obj) => obj[prop] === o[prop])) {
        unique.push(o);
      }
      return unique;
    }, []);
  }
}
