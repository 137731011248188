import { Car, CarsDriven } from './../modals/car';
import { Injectable } from "@angular/core";
import { catchError, timeout } from "rxjs/operators";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { map } from "rxjs/operators";
import * as moment from "moment";
//import { env } from "process";
import { environment } from "environments/environment";
import { data } from "jquery";

const options = {
  headers: new HttpHeaders()
    .set("Authorization", "Basic " + localStorage.getItem("auth"))
    .set("Accept", "application/json"),
    Authorization: 'Token ' + localStorage.getItem('token'),
  // withCredentials: true,
};

@Injectable({
  providedIn: "root",
})
export class CarsService {
  constructor(private http: HttpClient) {}

  car_images = {
    Malibu: "../../../assets/img/malibu_2020.png",
    Traverse: "../../../assets/img/traverse_2020.png",
    Suburban: "../../../assets/img/suburban_2020.png",
    "ES 350": "../../../assets/img/es350.jpg",
    Captiva: "../../../assets/img/captiva.jpg",
    Corolla: "../../../assets/img/corolla.png",
    Rav4: "../../../assets/img/rav4.jpg",
    Prado: "../../../assets/img/ToyotaPrado.png",
    Camry: "../../../assets/img/camry.png",
    Fortuner: "../../../assets/img/fortuner.png",
    Fortuner_Gxr: "../../../assets/img/fortuner.png",
    Yaris: "../../../assets/img/yaris.png",
    Taurus: "../../../../assets/img/taurus.png",
    Patrol: "../../../../assets/img/patrol.png",
  };
  //  payload = {
  //   'employee_id': 1,

  // }
  SelectedCar:Car;
  cardriven:CarsDriven;

  getCars(): Observable<any> {
    const url = environment.stscApiBase + "getAllCars/";
    return this.http
      .get(url, options,)
      .pipe(map(this.extractData), catchError(this.handleError));
  }

  private extractData(res: Response): any {
    const body = res;
    const cars = body['data']
    //console.log(cars);
    return cars || {};
  }

  private handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      console.error("An error occurred:", error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    return throwError("Something bad happened; please try again later.");
  }



  getCar(car_id): Observable<any> {
    const payload= {
      "car_id": car_id
    }
    const url = environment.stscApiBase + "getCar/";
    return this.http
      .post(url,payload)
      .pipe(map(this.extractCar), catchError(this.handleError));
  }
  getCarbylicence(car_id:object): Observable<any> {
    const payload=car_id;
    const url = environment.stscApiBase + "getCar/";
    return this.http
      .post(url,payload)
      .pipe(map(this.extractCar), catchError(this.handleError));
  }

  private extractCar(res: Response): any {
    const body = res;
    const car = body['data']
    //console.log(car);
    return car || {};
  }

  controlShift(params){
    let httOptions ={
      headers:new HttpHeaders()
      .set("Authorization", "Token " + localStorage.getItem("token"))
      .set("Accept", "application/json"),
      params: params
    }

    return this.http.get(environment.stscApiBase+"v2/updateCarShiftType",httOptions)
  }
}
