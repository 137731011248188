import { Component, OnInit, ViewChild } from "@angular/core";
import { DataTableDirective } from "angular-datatables";
import { Utils } from "app/helper/_utils";
import { CsvDataService } from "app/service/csv-data-service.service";
import { RestService } from "app/service/rest.service";
import * as moment from "moment";
import { FilterPipe } from "ngx-filter-pipe";
import { Subject } from "rxjs";

@Component({
  selector: "app-app-analytics",
  templateUrl: "./app-analytics.component.html",
  styleUrls: ["./app-analytics.component.css"],
})
export class AppAnalyticsComponent implements OnInit {

  constructor(
    private rest: RestService,
    private util: Utils,
    private filterpipe: FilterPipe,
    private csvService:CsvDataService
     ) {}

  total_employees = 0;
  total_drivers = 0;
  in_vacation = 0;
  not_active_in_company = 0;
  active_in_company = 0;
  users_not_updated = 0;
  users_in_latest = 0;
  latest_app_version = 0;
  dtTrigger: Subject<any> = new Subject<any>();
  dtInstance: DataTables.Api;
  isDtInitialized: boolean = false;
  dtOptions: DataTables.Settings = {
    pagingType: "full_numbers",
    pageLength: 25,
    order: [[0, "desc"]],
    ordering: true,
  };
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  users = [];

  filtered_users = [];
  loading: boolean;

  ngOnInit(): void {
    this.getAllUsers();
  }

  app_versions = [];

  fromNow(date) {
    return moment(date).fromNow();
  }

  getAllUsers() {
    this.rest.getAllUsers().subscribe((res) => {
      console.log(res.data);
      this.loading = true;
      this.users = res.data.sort((a, b) => {
        return (
          new Date(b["updated"]).getTime() - new Date(a["updated"]).getTime()
        );
      });
      this.loading = false;
      this.filtered_users = this.users;
      this.dtTrigger.next();

      this.total_employees = this.users.length;
      this.total_drivers = this.users.filter((us) => {
        if (us.role == "DRIVER") {
          return us;
        }
      }).length;
      this.in_vacation = this.users.filter((us) => {
        if (us.in_vacation) {
          return us;
        }
      }).length;
      this.not_active_in_company = this.users.filter((us) => {
        if (!us.active_in_company) {
          return us;
        }
      }).length;
      this.active_in_company = this.users.filter((us) => {
        if (us.active_in_company) {
          return us;
        }
      }).length;

      this.app_versions = this.users
        .map((us) => {
          return us.app_version_name;
        })
        .sort();
      let latest_version = this.app_versions[this.app_versions.length - 1];

      this.latest_app_version = latest_version;
      this.users_not_updated = this.users.filter((us) => {
        if (us.app_version_name != latest_version) {
          return us;
        }
      }).length;
      this.users_in_latest = this.users.filter((us) => {
        if (us.app_version_name == latest_version) {
          return us;
        }
      }).length;
    });
  }

  capitalize(val) {
    return this.util.capitalize(val);
  }

  filter_users(val) {
    // console.log(val);
    // this.filtered_users = [];

    switch (val) {
      case "all": {
        this.filtered_users = this.users;
        this.re_render_table();
        break;
      }
      case "driver": {
        this.filtered_users =[...this.users].filter((us) => {
          if (us.role == "DRIVER") {
            return us;

          }
        });
        this.re_render_table();
        break;
      }
      case "in_vacation": {
        this.filtered_users = [...this.users].filter((us) => {
          if (us.in_vacation) {
            return us;
          }
        });
        this.re_render_table();
        break;
      }
      case "latest": {
        this.filtered_users = [...this.users].filter((us) => {
          if (us.app_version_name === this.latest_app_version) {
            return us;
          }
        });
        this.re_render_table();
        break;
      }
      case "latest_not": {
        this.filtered_users = [...this.users].filter((us) => {
          if (us.app_version_name !== this.latest_app_version) {
            return us;
          }
        });
        this.re_render_table();
        break;

      }
      case "active": {
        this.filtered_users = [...this.users].filter((us) => {
          if (us.active_in_company) {
            return us;
          }
        });
        this.re_render_table();
        break;
      }
      case "not_active": {
        this.filtered_users = [...this.users].filter((us) => {
          if (!us.active_in_company) {
            return us;
          }
        });
        this.re_render_table();
        break;
      }
    }


    // console.log(this.users.length, this.filtered_users.length);
  }
  re_render_table(){
    this.dtElement.dtInstance?.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  export(){
    if (this.users.length > 0) {
      const items = [];
      this.users.forEach((line) => {
        const csvLine = {
          id: line["id"],
          active_in_company: line["active_in_company"],
          app_version_name: line["app_version_name"],
          car__license: line["car__license"],
          email: line["email"],
          full_name: line["full_name"],
          in_vacation: line["amount"],
          role: line["role"],
        };
        items.push(csvLine);
      });
      const name = "appusers_" + new Date().getTime();
      this.csvService.exportToCsv(name + ".csv", items);
    }
  }
}
